.locate {
    position: absolute;
    top: 4rem;
    right: 30%;
    border: none;
    z-index: 40;
    padding: 10px;
    background-color: rgba(86, 86, 86, 0.4);
    border-radius: 50%;
    box-shadow: -10px 12px 20px 0px #666;
    img {
        width: 100%;
        vertical-align: middle;
        cursor: pointer;
    }
}


.locate:focus{
    outline: none;
}

@media screen and (max-width:959px)  { 
    .locate{
        top: 37px;
        right: 10px;
        padding: 5px;
        background-color: transparent;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        img{
            width: 30px;
        }
    }
}

@media screen and (min-width:321px) and (max-width:639px) {
    .locate{
        top: 33px;
        right: 5px;
        img{
            width: 25px;
        }
    }

}