.search {
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 16.5vw;
    box-sizing: border-box;
    z-index: 30;
    input{
        padding: 0.5rem;
        font-size: 1.5rem;
        width: 100%;
        border-radius: 25px;
        box-shadow: 0px 7px 20px 0px #666;
        font-size: 1.4vw;
        box-sizing: border-box;
    }
}
[data-reach-combobox-popover] {
    z-index: 30;
    box-sizing: border-box;
}
  
input:focus{
    outline: none;
}

// @media screen and (min-width:640px) and (max-width:959px)  { 
//     .search{
//         width: 35vw;
//         top: 4rem;
//         input{
//             font-size: 2.5vw;
//         }
        
//     }
    
// }

@media screen and (max-width:900px) { 
    .search{
        width: 100vw;
        top: 35px;
        border: 1px solid #666;
        input{
            font-size: 3.5vw;
            border: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
        
    }
    
    
}
