.News_section{
    position: fixed;
    top: 0;
    right: -19px;
    z-index: 20;
    width: 30vw;
    height: 100%;
    padding-top: 57px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
    box-sizing: border-box;
    overflow-y: scroll;
    .header{
        background-color: white;
        width: 100%;
        h4{
            font-size: 0.94vw;
        }
    }
    .articles{
        background-color: white;
        padding-left: 15px;
        height: auto;
        width: 100%;
        overflow-x: hidden;
        border-radius: 20px;
        border: 1px solid rgb(161, 161, 161);
        box-shadow: -6px 1px 20px 3px #666;
        -moz-box-shadow: -6px 1px 20px 3px #666;
        -webkit-box-shadow: -6px 1px 20px 3px #666;
        box-sizing: border-box;
        .articlecontainer{
            width: 100%;
            display: inline-flex;
            border-top: 1px solid #DADCE0;
            border-bottom: 1px solid #DADCE0;
            overflow: hidden;
            padding: 5px;
            box-sizing: border-box;
            .article_marker{
                width: 30%;
                position: relative;
                img{
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    width: 75%;
                }
            }
            .article_title{
                width: 70%;
                height: 100%;
                text-align: initial;
                p{
                    font-weight: 600;
                    margin: 0;
                    height: auto;
                    padding: 10px 0;
                    font-size: 0.94vw;
                    padding: 0.5vw 0;
                    a{
                        color: black;
                        text-decoration: none;
                    }
                }
                span{
                    font-size: 14px;
                    font-weight: 500;
                    color: #70757a;
                    display: block;
                    font-size: 0.73vw;
                    margin-top: 10px;
                }
            }
        }
        .disclaimer{
            display: none;
        }
        .end{
            height: 300px;
            padding-top: 50px;
            a{
                color: #8a8787;
                font-size: 14px;
                display: none;
            }
        }
    }
    
}

@media screen and (min-width:959px)  {
    .News_section{
        height: 89vh;
        overflow-y: scroll;
        margin-top: 37px;
        padding: 20px;
        padding-left: 30px;
        .articles{
            height: 100%;
            overflow-y: scroll;
        }
    }
}




@media screen and (max-width:959px)  {    
    .News_section{
        width: 100vw;
        transition: transform 0.5s ease;
        padding: 0;
        .slidebttn{
            display: none;
        }
        .header{
            margin-top: 15px;
            h4{
                font-size: 20px;
                font-size: 3vw;
                margin: 0;
                margin-bottom: 5px;
            }
            
        }
        .window{
            height: 85vh;
            z-index: 5;
            pointer-events: none;
        }
        .articles{
            padding: 0;
            box-shadow: none;
            .articlecontainer{
                width: 100vw;
                padding: 5px 10px;
                .article_title{
                    p{
                        font-size: 16px;
                    }
                    span{
                        font-size: 10px;
                        height: 15%;
                    }
                }
                .article_marker{
                    img{
                        width: 50%;
                    }
                }
            }
            .end{
                a{
                    display: block;
                }
            }
            .disclaimer{
                display: block;
                text-align: start;
                padding: 0 10px;
                h5{
                    font-size: 14px;
                    text-align: center;
                    margin-bottom: 0;
                }
                h6{
                    margin-bottom: 0;
                }
                p{
                    font-size: 12px;
                }
                .sources{
                    font-size: 12px;
                }
            }
        }
    }
    
    .Open {
        
        top: 0;
        left: 0;
    }
    
    .Close {
        
        top: 0;
        left: 0;
    }

    // .News_section::before{
    //     content: '?';
    //     display: inline-block;
    //     width: 500px;
    //     height: 500px;
    //     background-color: red;
    // }
}


@media screen and (min-width:321px) and (max-width:639px) { 
    .News_section{
        .header{
            h4{
                font-size: 3vw;
            }
        }
        .end{
            a{
                display: block;
            }
        }
        .articles{
            .articlecontainer{
                .article_marker{
                    img{
                        width: 70%;
                    }
                }
            }
        }
    }

}

@media screen and (max-width:320px) { 
    .end{
        a{
            display: block;
        }
    }
   
}
